import { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { Tooltip } from "primereact/tooltip";
import agent from "../../../agent";
import '../order.scoped.css'

const customInterval = (asyncFunc, timerId, delay, ...args) => {
  const timerIdOuter = setTimeout(async function request() {
    await asyncFunc(...args);
    const timerIdInner = setTimeout(request, delay);
    timerId.current = timerIdInner;
  }, delay);

  timerId.current = timerIdOuter;
};

const ModalReports = ({isModalOpen, onClose, reports, onCreateReport, onDownload}) => {
  const timerId = useRef(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isStatusesLoading, setIsStatussesLoading] = useState(false);
  const [statusses, setStatusses] = useState({
    report: false,
    reportName: '',
    cue: false,
    cueName: ''
  });
  
  useEffect(() => {
    getStatus(true);
    customInterval(getStatus, timerId, 5000);
    return () => {
      timerId.current && clearTimeout(timerId.current);
    };
  }, []);

  const fetchStatusses = async () => {
    try {
      return await agent.Reports.getStatus();
    } catch (err) {
      console.error(err);
      return {name: ''};
    }
  };

  const fetchProduceStatusses = async () => {
    try {
      return await agent.Reports.getProduceStatus(); 
    } catch (err) {
      console.error(err);
      return {names: []};
    }
  };

  const getStatus = async (withLoader = false) => {
    try {
      withLoader && setIsStatussesLoading(true);
      const status = await fetchStatusses();
      const produceStatus = await fetchProduceStatusses(); 
      setStatusses({
        report: !!status?.name,
        reportName: status?.name,
        cue: !!produceStatus?.names?.length,
        cueName: produceStatus?.names[0]
      });
      withLoader && setIsStatussesLoading(false);
    } catch (err) {
      console.error(err);
      withLoader && setIsStatussesLoading(false);
    }
  }; 

  const handleNewReport = async () => {
    try {
      if (statusses.cue) return;
      if (selectedReport?.code) {
        setIsStatussesLoading(true);
        const result = await onCreateReport(selectedReport?.code, selectedReport?.category);
        setStatusses({cueName: result?.name, cue: true, report: false, reportName: ''});
        setTimeout(() => {
          setIsStatussesLoading(false);
        }, 10000);
      }
    } catch (err) {
      console.error(err);
      setIsStatussesLoading(false);
    }
  };

  const handleDownload = async () => {
    const result = await agent.Reports.downloadReport();
    onDownload(result);
  };

  const onSelect = (e) => {
    const report = reports?.find(item => item?.code === e?.value) || null;
    setSelectedReport(report);
  };

  const renderFooter = () => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          label="Закрыть" 
          icon="pi pi-times" 
          onClick={onClose} 
          className="p-button-text p-mr-2" 
        />
        <Button 
          label={"Сформировать отчет"}
          onClick={handleNewReport} 
          autoFocus 
          tooltip={'Внимание: новый отчет заменит старый. Сохраните данные, если нужно.'}
          tooltipOptions={{position: 'top', className: 'tooltip-report'}}
          loading={isStatusesLoading}
          disabled={statusses.cue || !selectedReport?.code || isStatusesLoading}
          className="p-mr-2"
        />
        {statusses.reportName ? 
          <Button 
            label={`Скачать отчёт: "${statusses.reportName || ''}"`} 
            onClick={handleDownload} 
            autoFocus 
            disabled={isStatusesLoading && !statusses.report}
          /> : null
        }
      </div>
    );
  }

  return (
    <Dialog
      header="Детализированный отчет"
      visible={isModalOpen}
      style={{ width: '50vw' }}
      onHide={onClose}
      footer={renderFooter()}
    >
      {statusses.cue ? 
        <div className="p-mb-2">
          <Message severity="info" text={`Отчет формируется: "${statusses?.cueName || ''}".`} />
        </div> : null
      }
      <div className="p-field p-col-1 p-md-2">
        <label htmlFor="reportId">Тип отчета</label>
        <Dropdown style={{minWidth: '250px'}} 
          inputId="reportId"
          value={selectedReport?.code}
          options={reports}
          scrollHeight={400}
          onChange={onSelect}
          optionLabel="nameRu" 
          optionValue="code" 
          showClear
        />
      </div>
      <Tooltip target=".logo" mouseTrack mouseTrackLeft={10}/>
    </Dialog>
  );
};

export default ModalReports;